<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
/**
 * Page-terms component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>


<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Terms of Services </h4>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item active" aria-current="page">Terms</li>
                                    <li class="breadcrumb-item"><a href="/privacy">Privacy</a></li>
                                    <li class="breadcrumb-item"><a href="/refund">Refund</a></li>
                                    <li class="breadcrumb-item"><a href="/disclosure">Disclosure</a></li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Terms & Conditions -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">
                            <h5 class="card-title">Introduction :</h5>
                            







<h4 style="text-align: left;"><strong>We have some conditions!</strong></h4>
<p style="text-align: left;">Yes, we have. To complete a project successfully and keep relationship well as always it’s good to have some working condition.</p>

<h5 style="text-align: left;"><strong>Standard  terms and conditions of Contract and acceptance for 
Karukaj DIGITAL– (2015)*</strong></h5>
<p style="text-align: left;">Business Name: Karukaj DIGITAL (or associated partner)</p>

<h5 style="text-align: left;">The Client: The customer</h5>
<p style="text-align: left;">Goods or services: Company has supplied design or printing or other POS/advertising/marketing/website/photographic material. POS: point of sale Proof confirmation form: The companies standard printed form that upon being signed by the client is the clear confirmation to the company that the client has, read through all text and copy (supplied or otherwise) and checked all artwork and logos/accreditations and images on a final proof supplied by the company in what ever agreed form (printed PDF).


<strong><br>
<br>
1.</strong> Non account holding clients may be asked to either pre-pay or pay for services and goods upon delivery. A Pro-Forma invoice will be issued in both cases and final payment terms will be agreed between the company and the client in writing by way of e-mail/letter or fax.</p>

<strong>2.</strong> Payment must be done in the Karukaj DIGITAL's assigned gateways.


<strong><br>
<br>
3.</strong> All company invoices will be settled by the client in accordance with the original quotation and agreement.


<strong><br>
<br>
4.</strong> Clients who have an account with the company will pay in full on or before 30 days from date of invoice unless otherwise agreed in writing with the directors of the company. The company reserves the right to charge interest on overdue balances at the rate of 3.5% per calendar month, the company also reserves the right to charge the client any bank charges created and an administration charge of 
<b>$25.00</b> for overdue invoices.


<strong><br>
<br>
&nbsp;&nbsp;&nbsp; 4a.</strong> Credit: we may seek the services of a credit checking supplier with new clients requesting a credit account and may ask that any goods or services supplied be paid “Pro-Forma or Cash on delivery if it is deemed that a credit account cannot be given due to a low or poor credit rating/score.


<strong><br>
&nbsp;&nbsp;&nbsp; 4b.</strong> We may from time to time use the services of an invoice factoring company, who will provide invoice finance to the company and who will send statements and chase overdue invoices on our behalf (the companies). they may also seek independent credit checking on a client.


<strong><br>
<br>
5.</strong> The company reserves the right to charge the amount of any value added tax payable whether or not included on the estimate or invoice.


<strong><br>
<br>
6.</strong> Any reason for partial or non payment must be notified by fax or special/recorded delivery letter within 3 days of 
receipt of goods or services. we will not accept verbal or e mail communications for partial or non payment of goods or services. The company will not accept responsibility for any errors, shortfalls or damage to delivered goods after this date. Estimated delivery times are not contractual. Goods excepted entirely at owners (clients) risk.


<strong>7.</strong> In the event of a query or dispute concerning any part of the invoice, such part will be treated as severable from the remainder of the invoice(s) which shall be settled either in accordance with point (1) or (4). Full ownership and title of the services or goods are retained by the company until full payment received. O&amp;OE, force majeure.


<strong><br>
<br>
8.</strong> If a client disputes any part of the service or goods received by the company for what ever reason. the client will return all goods that are in query for further examination (where possible). Any of the goods supplied and returned that fall short of original amount supplied will be chargeable at the full quoted rate and the query raised will be deemed nil and void unless otherwise agreed in writing with the directors of  the company.


<strong><br>
<br>
9.</strong> If the amount of goods delivered is to large to return by the client, the client will pay an agreed amount of expenses and courier charges for the company to inspect and arrange a return to a supplier.


<strong><br>
10.</strong> The company reserves the right to vary the clients costs if the company is subjected to varying labour and material costs from its suppliers. Clients would be informed of any variance in writing.


<strong><br>
<br>
11. </strong>Design: The client will either sign the companies standard “proof confirmation form” or provide a purchase order number that acts as final proof confirmation to the company (where agreed in writing for specific clients) to proceed to produce the goods and services for the client. We reserve the right to suspend or delay the production of clients goods or services if they do not complete and sign the companies “proof confirmation form” without incurring any liability what so ever to the company. We will pass on any charges to the
client incurred from our supplier if a delay causes the supplier problems arising from any client delay.


<strong><br>
<br>
12.</strong> It is the clients full responsibility to ensure the accuracy of all content of printed material being font style and font size /copy and grammar / text / images / logos and accreditations/voucher dates and addresses and contact details/machine readable codes/symbols, colour and tints and T &amp; C’s  etc. within a final proof supplied by the company. We the company will not accept any responsibility or liability what so ever if the goods or services are produced incorrectly after the signing of the companies “proof confirmation form”. A charge may be made to cover any re-printing or re-supplying of goods and services.


<strong><br>
<br>
13.</strong> If any goods or services supplied are incorrect after the signing of the “proof confirmation form” where the clients final proofs are checked and signed off but the final goods are incorrect to that of the final signed off proof or damaged the company will if instructed offer to supply new goods or services to rectify the error without incurring any further liability to the company. Any error in the final signed off proofing that is the same as the error on the supplied goods will be the full responsibility of the client (see points 11 &amp; 12).


<strong><br>
<br>
&nbsp;&nbsp;&nbsp; 13a.</strong> The risk and ownership in all goods delivered in connection with the work shall pass to the client on delivery. 
<br>
<b>&nbsp;&nbsp;&nbsp; 13b.</b> We the company are not obliged to check clients final artwork for any errors the company will not make decisions on a clients behalf that it is ready to send to a supplier. We will only send final artwork if our standard proof confirmation form is completed and signed by an authorised signature/agent.
<br>
&nbsp;<p>


<strong>14.</strong> We reserve the right to decline any or all of our services at anytime for anything that we consider to be of an illegal, libelous, offensive or racist nature or which may involve an infringement of a third parties rights.
</p>
<p>


<strong>15.</strong> Proofs: Proofs of all work signed by way of  the companies “proof confirmation form” as excepted and ready to produce the goods, the company shall except no liability or responsibility for errors not corrected by the client prior to sending artwork files to our supplier. We reserve the right to charge for amending final proofs or correcting any errors and re-supplying new files.
</p>
<p>


<strong>16.</strong> It is the clients responsibility to keep and maintain a copy of any original electronic file. 
</p>
<p>17.Colour paper proofs: The company will supply standard laser proofs as final proofs unless otherwise agreed. Due to differences in equipment, paper, inks and other conditions between colour proofing and supplier’s machinery and file reading equipment, a reasonable variation in colour between colour proofs and the completed job will be deemed acceptable unless otherwise agreed in writing.
</p>
<p>


<strong>18.</strong> If work should be suspended at the request of or delayed through any default of the client for a period of 30 calendar days the company shall then be entitled to payment for work carried out. Materials ordered and other additional cost including storage.
</p>
<p>


<strong>19.</strong> The company will not be held liable for indirect loss or third party claims occasioned by delay or error in completing the clients work or for any loss to the client arising from delay in transit or error whether as a result of the companies supplier or supplied transport or otherwise.
</p>
<p>


<strong>20.</strong> The company has full copyright and ownership of all material created and supplied by and for the client until the client has paid in full.
</p>
<p>


<strong>21.</strong> Where the company is requested to produce and supply advertisements, POS, exhibition material, signage and website artwork the client will as in points (11) &amp; (12) sign a proof conformation form that is a clear indication and instruction that the client excepts the goods and services are ready to send to our, or the clients supplier(s). We will not be liable or except any responsibility for errors found after the “proof confirmation form” is signed. Also point (19) applies to this term and condition.
</p>
<p>


<strong>22.</strong> The client agrees to compensate the company in respect of all costs, damages or other charges falling upon the company as a result of legal action or threatened legal action arising from any material or services provided to the client by the company.
</p>
<p>


<strong>23.</strong> When a client provides a purchase order number this is our confirmation that the client has approved the final goods and services required. Any errors after the providing of a purchase order are the client’s full responsibility.
</p>
<p>


<strong>24.</strong> If a client becomes insolvent and fails to pay for the goods or services supplied we reserve the right to take back the goods and services and enter the customer’s premises to do so.
</p>
<p>


<strong>25. </strong>Supplied images. Where a client supplies images or logos etc. we the company except no responsibility whatsoever for copyright or legal ownership of this material and all responsibility falls on the client for ensuring they can legally use the material. We are not responsible for checking any material is legal or otherwise that a client supplies for his goods or services.
</p>
<p>


<strong>26.</strong> Electronic files. If a client supplies electronic files of any kind by post or e-mail they will ensure all files are virus checked and free of any malicious bugs and viruses. any damage caused by unchecked files to the companies equipment or systems that requires repair or replacement. The client will pay for this in full.
</p>
<p>


<strong>27.</strong> The company shall at no time communicate with any third party about a clients work or business unless agreed between the parties to do so in writing.
</p>
<p>


<strong>28. </strong>LAW: these conditions and all other express and implied terms of the contract shall be governed and construed with the laws of Bangladesh.</p>





                            <div class="mt-3">
                                <a href="javascript:void(0)" class="btn btn-primary mt-2 mr-2">Accept</a>
                                <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Decline</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->




    <!--  -->

<br><br><br>
      
        <div class="container mt-4 mt-lg-0">
            <div class="row align-items-center mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                        <h4 class="mb-4">Need help? We're always here for you</h4>
                        <p class="text-muted mb-0 para-desc">Start working with <span class="my-color-1 font-weight-bold">Karukaj</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">
                        <a href="/privacy" class="btn btn-soft-primary">Read Privacy Policy<arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->



    <!--end section-->












        
    </section>
    <!--end section-->
    <!-- End Terms & Conditions -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-light back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
