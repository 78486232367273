<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
  GithubIcon,
  YoutubeIcon,
  GitlabIcon,
  PhoneIcon,
  BookmarkIcon,
  GlobeIcon,
  GiftIcon,
} from "vue-feather-icons";
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
    GithubIcon,
    YoutubeIcon,
    GitlabIcon,
    PhoneIcon,
    BookmarkIcon,
    GlobeIcon,
    GiftIcon,
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->



    <footer class="footer">





      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="images/logo-light.png" height="35" alt="" />
            </a>
            <p class="mt-4">
              At Karukaj, we pride ourselves on giving companies an amazing 
              product they feel they couldn't get elsewhere. Adding passion into each project makes all the difference.
            </p>
            <ul class="list-unstyled social-icon social mb-0 mt-4">
              <li class="list-inline-item">
                <a  title="Karukaj Digital" target="_blank"  href="https://www.facebook.com/karukaj.digital" class="rounded">
                  <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a  title="Karukaj Digital" target="_blank" href="https://www.instagram.com/karukaj.digital" class="rounded">
                  <instagram-icon
                    class="fea icon-sm fea-social"
                  ></instagram-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a  title="Karukaj Digital" target="_blank" href="https://www.twitter.com/karukaj_digital" class="rounded">
                  <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a  title="Karukaj Digital" target="_blank" href="https://www.linkedin.com/company/karukajdigital/" class="rounded">
                  <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                </a>
              </li>

              <li class="list-inline-item ml-1">
                <a  title="Karukaj Digital" target="_blank" href="https://codepen.io/karukaj" class="rounded">
                  <globe-icon class="fea icon-sm fea-social"></globe-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a  title="Karukaj Digital" target="_blank" href="https://www.github.com/karukaj" class="rounded">
                  <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
                </a>
              </li>
             <li class="list-inline-item ml-1">
                <a  title="Karukaj Digital" target="_blank" href="https://www.linkedin.com/company/karukaj-digital/" class="rounded">
                  <bookmark-icon class="fea icon-sm fea-social"></bookmark-icon>
                </a>
              </li>
            </ul>
            <!--end icon-->
          </div>
          <!--end col-->

          <div class="footer-special-width col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Quick Links</h5>
            <ul class="list-unstyled footer-list mt-4">
                             <li>
                <router-link to="/estimate" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Get an Estimate</router-link
                >
              </li>

                <li>
                <router-link to="/explore" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Explore More</router-link
                >
              </li>


              <li>
                <router-link to="/about" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> About Us
                  </router-link
                >
              </li>

              <li>
                <router-link to="/support" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Support Center</router-link
                >
              </li>

            </ul>
          </div>
          <!--end col-->

          <div class="footer-special-width col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Usefull Links</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/terms" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Terms of
                  Service</router-link
                >
              </li>
              <li>
                <router-link to="/privacy" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Privacy
                  Policy</router-link
                >
              </li>
              <li>
                <router-link to="/refund" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Refund Policy</router-link
                >
              </li>

              <li>
                <router-link to="/disclosure" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  FTC Disclosure</router-link
                >
              </li>
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Creative Newsletter</h5>

            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="foot-subscribe form-group">

                    <div class="position-relative">
                      <mail-icon class="fea icon-sm icons"></mail-icon>
                      <input
                        type="email"
                        name="email"
                        id="emailsubscribe"
                        class="form-control pl-5 rounded"
                        placeholder="Your email : "
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <input
                    type="submit"
                    id="submitsubscribe"
                    name="send"
                    class="btn btn-soft-primary btn-block"
                    value="Subscribe"
                  />
                </div>
              </div>
            </form>
           <p class="mt-4 responsive-small">Subscribe for latest offer & secrets via email. <i class="mdi mdi-chevron-right mr-1"></i> If still have a burning question? 
           ~ Trust our support team</p>


          </div>
          <!--end col-->






            <div class="col-md-7">
              <div class="section-title">
                <div class="media">
                   <img src="images/satis.png" class="avatar avatar-60x" alt="">

                  <div class="media-body ml-md-4 ml-3">
                    <h4 class="font-weight-bold text-light title-dark mb-1">
                      Satisfaction Guaranteed
                    </h4>
                    <p class="text-white-50 mb-0">
                     Start with best brand domain name and hosting for the brightest of ideas
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->



            <div class="media contact-detail align-items-center mt-3 div-center">
                            <div class="icon">
                                <phone-icon class="fea icon-m-md text-light mr-3"></phone-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0 text-light">WhatsApp</h5>
                                <a href="tel:+8801714641541" class="my-color-1">+880 1714-641-541</a><br>
                                
                            </div>
                        </div>



                        <div class="media contact-detail align-items-center mt-3 div-center">
                            <div class="icon">
                                <phone-icon class="fea icon-m-md text-light mr-3"></phone-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0 text-light">Phone</h5>
                                <a href="tel:+8801714641541" class="my-color-1">+880 1714-641-541</a><br>
                                
                            </div>
                        </div>
                    




            <!--end col-->
 <div class="donate-border"></div>

          <div class="col-12 text-center">
                   
<span><img src="images/home/Donate-IMGAE.png" class="donation-img"  alt=""></span> 

          </div>


        </div>
        <!--end row-->
      </div>
      <!--end container-->
      









    </footer>
    <!--end footer-->
    



    <footer class="footer footer-bar footer-bg">
      <div class="footer-wave">

<!--Content before waves-->

<!--Waves Container-->
<div>

  
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">



<!--Start WhatsApp Button-->
<input class="chat-menu hidden" id="offchat-menu" type="checkbox"/>
<div class="sticky-button" id="sticky-button">
	<label for="offchat-menu">
		<svg class="chat-icon" viewBox="0 0 24 24">
			<path d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"/>
		</svg>
		<svg class="close-icon" viewBox="0 0 512 512">
			<path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"/>
		</svg>
	</label>
</div>
<div class="sticky-chat">
	<div class="chat-content">
		<div class="chat-header">
			<svg viewBox="0 0 32 32">
				<path d="M24,22a1,1,0,0,1-.64-.23L18.84,18H17A8,8,0,0,1,17,2h6a8,8,0,0,1,2,15.74V21a1,1,0,0,1-.58.91A1,1,0,0,1,24,22ZM17,4a6,6,0,0,0,0,12h2.2a1,1,0,0,1,.64.23L23,18.86V16.92a1,1,0,0,1,.86-1A6,6,0,0,0,23,4Z"/>
				<rect height="2" width="2" x="19" y="9"></rect>
				<rect height="2" width="2" x="14" y="9"></rect>
				<rect height="2" width="2" x="24" y="9"></rect>
				<path d="M8,30a1,1,0,0,1-.42-.09A1,1,0,0,1,7,29V25.74a8,8,0,0,1-1.28-15,1,1,0,1,1,.82,1.82,6,6,0,0,0,1.6,11.4,1,1,0,0,1,.86,1v1.94l3.16-2.63A1,1,0,0,1,12.8,24H15a5.94,5.94,0,0,0,4.29-1.82,1,1,0,0,1,1.44,1.4A8,8,0,0,1,15,26H13.16L8.64,29.77A1,1,0,0,1,8,30Z"/>
			</svg>
			<div class="title">WhatsApp Chat <span>Messenger Marketing Platform</span>
			</div>
		</div>

    
    <div class="chat-text">
    <img src="https://members.karukaj.net/uploads/staff_profile_images/1/small__300x300.png" class="staff-profile-image-small">  <span>Question? Chat with us

</span>

      </div>
		<div class="chat-text"> <img src="https://members.karukaj.net/uploads/staff_profile_images/1/small__300x300.png" class="staff-profile-image-small"> <span>We reply immediately, <br/>
      if we can't we'll reply faster</span>
      <span class="typing">
        <svg viewBox="0 0 512 512">
          <circle cx="256" cy="256" r="48"></circle>
          <circle cx="416" cy="256" r="48"></circle>
          <circle cx="96" cy="256" r="48"></circle>
        </svg>
       </span>
      </div>
	  </div>
  <a class="chat-button" href="https://wa.me/8801714641541?text=Hi%20there!" rel="nofollow noreferrer" target="_blank">
    <span>Tap to send us hi</span>
    
    
    <svg viewBox="0 0 32 32">
      <path d="M19.47,31a2,2,0,0,1-1.8-1.09l-4-7.57a1,1,0,0,1,1.77-.93l4,7.57L29,3.06,3,12.49l9.8,5.26,8.32-8.32a1,1,0,0,1,1.42,1.42l-8.85,8.84a1,1,0,0,1-1.17.18L2.09,14.33a2,2,0,0,1,.25-3.72L28.25,1.13a2,2,0,0,1,2.62,2.62L21.39,29.66A2,2,0,0,1,19.61,31Z"/>
    </svg> 
    
    
    
   </a>
  </div>
<!--Start WhatsApp Button-->







   <p class="mycopyright">
     All the entirety of this domain is protected by copyright © 2010 - {{ new Date().getFullYear() }} <br> <small> Stream url: 
      <a href="https://www.karukaj.com.bd/"
                  target="_blank"
                  class="text-reset"
                  > karukaj.com.bd</a> | Stay with Good Works!</small>
              </p>
            </div>
          </div>
          <!--end col-->


          
          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul class="list-unstyled text-sm-right mb-0">
              <li class="list-inline-item">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/american-ex.png"
                    class="avatar avatar-ex-sm"
                    title="American Express"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/discover.png"
                    class="avatar avatar-ex-sm"
                    title="Discover"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/master-card.png"
                    class="avatar avatar-ex-sm"
                    title="Master Card"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/paypal.png"
                    class="avatar avatar-ex-sm"
                    title="Paypal"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/visa.png"
                    class="avatar avatar-ex-sm"
                    title="Visa"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->






<svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
<defs>


<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>


<g class="parallax">
<use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
<use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
<use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
<use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
</g>
</svg>
</div>


<!--Waves end-->

</div>
<!--Header ends-->

<!--Content starts-->






    </footer>
    <!--end footer-->









    <div class="fixed-footer">






      <div class="footer-call-us footer-15 black-color-font">

<a href="tel:+8801714641541"
  ><i class="uil uil-outgoing-call"></i>  Call us at <b>01714-641-541</b></a
>
</div>





    </div>






  </div>


  
</template>
